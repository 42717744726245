@font-face {
  font-family: 'Poppins-Regular';
  src: url('assets/fonts/Poppins-Regular.ttf') format('opentype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Poppins-Medium';
  src: url('assets/fonts/Poppins-Medium.ttf') format('opentype');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('assets/fonts/Poppins-SemiBold.ttf') format('opentype');
  font-style: normal;
  font-weight: 600;
}

* {
  padding: 0px;
  margin: 0px;
  border: 0px;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: unset;
}
body {
  margin: 0;
  font-family: Poppins-Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

