.uploadPopup {
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 999;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 360px;
    width: 600px;
    background-color: #fff;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
    border-radius: 4px;
    padding: 20px;

    .cropperWrpr {
      height: calc(100% - 60px);
      position: relative;
    }
  }
}
