.inputLine {
  display: flex;
  gap: 0px 20px;
}

.signature {
  border: 1px solid #5c5c5c;
}

.successPopup {
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 999;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    background-color: #fff;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
    border-radius: 4px;
    padding: 20px;
    position: relative;
  }
}
